import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '@env/environment';
import {HttpClient} from '@angular/common/http';
import {GuiaRemision} from '@app/domain/guia/guia-remision';
import {FileStorage} from '@app/domain/file-storage';
import {DomSanitizer} from '@angular/platform-browser';
import {promiseBlob2Data} from '@app/utils/util-functions';
import {QzEscPosService} from '@app/service/qz-esc-pos.service';
import {CoffeEscPosService} from '@app/service/coffe-esc-pos.service';
import {DataSend} from '@app/domain/data-send';
import {LoginService} from "@app/service/login.service";

@Injectable({
    providedIn: 'root'
})
export class DespatchAdviceService {

    private baseUrl: string = environment.endpointApi;

    constructor(private http: HttpClient, private sanitizer: DomSanitizer,
                private qzEscPosService: QzEscPosService,
                private coffeEscPosService: CoffeEscPosService,
                private loginService: LoginService) {
    }

    save(model: GuiaRemision): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/despatch`, model);
    }

    getNextNumber(serie): Observable<any> {
        return this.http.get(`${this.baseUrl}/despatch/next-number/${serie}`);
    }

    enviarSunat(model): Observable<void> {
        return this.http.post<void>(`${this.baseUrl}/despatch/send-sunat`, model);
    }

    getStatus(id): Observable<void> {
        return this.http.get<void>(`${this.baseUrl}/despatch/${id}/get-status`);
    }

    findByIds(ids: string, hideLoader?: boolean): Observable<any> {
        let options;
        if (hideLoader) {
            options = {
                headers: {hideLoader: 'S'}
            };
        } else {
            options = {};
        }
        return this.http.get<any>(`${this.baseUrl}/despatch/search?ids=${ids}`, options);
    }

    findFileStorageByIdAndTipoArchivo(idTicket, tipoArchivo): Observable<FileStorage> {
        return this.http.get<FileStorage>(`${this.baseUrl}/despatch/file?id=${idTicket}&tipoArchivo=${tipoArchivo}`);
    }

    sendEmail(model): Observable<void> {
        return this.http.post<void>(`${this.baseUrl}/despatch/send-email`, model);
    }

    findAllPage(params: string): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/despatch?${params}`);
    }

    getDataSend(guia: GuiaRemision): DataSend {
        const url = `${this.getUrlPdf(guia, 'A4')}&time=${new Date().getTime()}`;
        return  {
            id: guia.id,
            nameMessage: `${guia.serie}-${guia.numero}-${new Date().getTime()}`,
            archivos: [],
            asunto: `Guía electrónica ${guia.serie}-${guia.numero}`,
            emails: [],
            number: '',
            texto: '',
            url,
            messageWhatsappWeb: `Hola! *${this.loginService.getLogin().user.empresa.nombreComercial.replace('&', 'Y')}* adjunta su guía electrónicoa con número ${guia.serie}-${guia.numero}, muchas gracias por su preferencia. Link del PDF: ${url}`,
            textMessage: `Hola! *${this.loginService.getLogin().user.empresa.nombreComercial}* adjunta su guía electrónica con número *${guia.serie}-${guia.numero}*, muchas gracias por su preferencia.`,
            type: 'ticket'
        };
    }

    openPdf(guia: GuiaRemision, options: any): Observable<any> {
        const url = this.getUrlPdf(guia.id, options.type);
        return new Observable<any>(observer => {
            this.http.get(url, {responseType: 'blob'}).subscribe(blob => {
                promiseBlob2Data(blob).subscribe(data => {
                    const response: any = {
                        urlViewer: this.sanitizer.bypassSecurityTrustResourceUrl('/assets/pdfjs/web/viewer.html?file=' + data.objectUrl),
                        url: data.objectUrl,
                        originalUrl: url,
                        urlSafe: this.sanitizer.bypassSecurityTrustResourceUrl(url),
                        base64: data.base64
                    };
                    observer.next(response);
                    observer.complete();
                });
            });
        });
    }
    getUrlPdf(id, tipo: string) {
        return `${environment.endpointApi}/public/pdf/dispatch/${id}?tipo=${tipo}`;
    }

}
