import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Office} from '@app/domain/office';
import {
    ClearSesion, SetAccessToken, SetAppVersion,
    SetCompany, SetCompanySelected,
    SetConfig,
    SetLogin,
    SetOffice,
    SetRoles,
    SetSaleStation,
    SetStatusClientWhatsappQr,
    SetStatusClientWhatsappSession,
    SetStatusClientWhatsappSocket,
    SetStatusClientWhatsappEvolution
} from '@app/state/sesion/sesion.actions';
import {SaleStation} from '@app/domain/sale-station';
import {ConfigCompany} from '@app/domain/config-company';
import {LoginResponse} from '@app/domain/login-response';
import {Injectable} from '@angular/core';
import {Company} from '@app/domain/company';
import { WhatsappSessionStatusI } from '@app/domain/whatsapp-status-session';


export class SesionStateModel {
    config: ConfigCompany;
    office: Office;
    saleStation: SaleStation;
    login: LoginResponse;
    roles: string[];
    company: Company;
    companySelected: Company;
    appVersion: string;
    statusClientWhatsappSocket: boolean; 
    statusClientWhatsappSession: WhatsappSessionStatusI;
    statusClientWhatsappQr: boolean;
    statusClientWhatsappEvolution: 'open' | 'close' | 'connecting';
    
}

@Injectable()
@State<SesionStateModel>({
    name: 'sesion',
    defaults: {
        config: null,
        office: null,
        saleStation: null,
        login: null,
        roles: [],
        company: null,
        companySelected: null,
        appVersion: null,
        statusClientWhatsappSocket: false,
        statusClientWhatsappSession: null,
        statusClientWhatsappQr: false,
        statusClientWhatsappEvolution: 'close'
    }
})

export class SesionState {

    constructor() {
    }

    @Selector()
    static getOffice(state: SesionStateModel) {
        return state.office;
    }

    @Selector()
    static getSaleStation(state: SesionStateModel) {
        return state.saleStation;
    }

    @Selector()
    static getConfig(state: SesionStateModel) {
        return state.config;
    }

    @Selector()
    static getLogin(state: SesionStateModel) {
        return state.login;
    }

    @Selector()
    static getRoles(state: SesionStateModel) {
        return state.roles;
    }

    @Selector()
    static getCompany(state: SesionStateModel) {
        return state.company;
    }

    @Selector()
    static getCompanySelected(state: SesionStateModel) {
        return state.companySelected;
    }

    @Selector()
    static getAppVersion(state: SesionStateModel) {
        return state.appVersion;
    }

    @Selector()
    static getStatusClientWhatsappSession(state: SesionStateModel) {
        return state.statusClientWhatsappSession;
    }

    @Selector()
    static getStatusClientWhatsappQr(state: SesionStateModel) {
        return state.statusClientWhatsappQr;
    }

    @Selector()
    static getStatusClientWhatsappEvolution(state: SesionStateModel) {
        return state.statusClientWhatsappEvolution;
    }

    @Selector()
    static getStatusClientWhatsappSocket(state: SesionStateModel) {
        return state.statusClientWhatsappSocket;
    }

    @Action(SetConfig)
    setConfig({getState, setState}: StateContext<SesionStateModel>, {payload}: SetConfig) {
        const state = getState();
        setState({
            ...state,
            config: payload.config,
        });
    }

    @Action(SetRoles)
    setRoles({getState, setState}: StateContext<SesionStateModel>, {roles}: SetRoles) {
        const state = getState();
        setState({
            ...state,
            roles
        });
    }


    @Action(SetOffice)
    setOffice({getState, setState}: StateContext<SesionStateModel>, {payload}: SetOffice) {
        const state = getState();
        setState({
            ...state,
            office: payload.office,
        });
    }

    @Action(SetSaleStation)
    setSaleStation({getState, setState}: StateContext<SesionStateModel>, {payload}: SetSaleStation) {
        const state = getState();
        setState({
            ...state,
            saleStation: payload.saleStation,
        });
    }

    @Action(SetLogin)
    setLogin({getState, setState}: StateContext<SesionStateModel>, {login}: SetLogin) {
        const state = getState();
        setState({
            ...state,
            login
        });
    }

    @Action(ClearSesion)
    clearSesion({getState, setState}: StateContext<SesionStateModel>, {}: ClearSesion) {
        const state = getState();
        setState({
            ...state,
            config: null,
            office: null,
            login: null
        });
    }

    @Action(SetCompany)
    setCompany({getState, setState}: StateContext<SesionStateModel>, {company}: SetCompany) {
        const state = getState();
        setState({
            ...state,
            company,
        });
    }

    @Action(SetCompanySelected)
    setCompanySelected({getState, setState}: StateContext<SesionStateModel>, {companySelected}: SetCompanySelected) {
        const state = getState();
        setState({
            ...state,
            companySelected,
        });
    }

    @Action(SetAppVersion)
    setAppVersion({getState, setState}: StateContext<SesionStateModel>, {appVersion}: SetAppVersion) {
        const state = getState();
        setState({
            ...state,
            appVersion,
        });
    }

    @Action(SetStatusClientWhatsappSession)
    SetStatusClientWhatsappSession({getState, setState}: StateContext<SesionStateModel>, {statusClientWhatsappSession}: SetStatusClientWhatsappSession) {
        const state = getState();
        setState({
            ...state,
            statusClientWhatsappSession,
        });
    }

    @Action(SetStatusClientWhatsappQr)
    SetStatusClientWhatsappQr({getState, setState}: StateContext<SesionStateModel>, {statusClientWhatsappQr}: SetStatusClientWhatsappQr) {
        const state = getState();
        setState({
            ...state,
            statusClientWhatsappQr,
        });
    }

    @Action(SetStatusClientWhatsappEvolution)
    SetStatusClientWhatsappEvolution({getState, setState}: StateContext<SesionStateModel>, {statusClientWhatsappEvolution}: SetStatusClientWhatsappEvolution) {
        const state = getState();
        setState({
            ...state,
            statusClientWhatsappEvolution,
        });
    }

    @Action(SetStatusClientWhatsappSocket)
    SetStatusClientWhatsappSocket({getState, setState}: StateContext<SesionStateModel>, {statusClientWhatsappSocket}:SetStatusClientWhatsappSocket ) {
        const state = getState();
        setState({
            ...state,
            statusClientWhatsappSocket,
        })
    }

    @Action(SetAccessToken)
    setAccessToken({ getState, setState }: StateContext<SesionStateModel>, { accessToken }: SetAccessToken) {
        const state = getState();
        const updatedLogin = {
            ...state.login, // Mantenemos el resto del objeto `login`
            accessToken: accessToken // Solo actualizamos el `accessToken`
        };
        setState({
            ...state,
            login: updatedLogin
        });
    }


}
