import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';
import {Observable, tap} from 'rxjs';
import {Serie} from '../domain/serie';
import {WhatsappMessageRequest} from '@app/domain/whatsapp-message-request';
import {WhatsappDocumentRequest} from '@app/domain/whatsapp-document-request';
import {formatNumberWhatsapp} from '@app/utils/util-functions';
import {forkJoin} from "rxjs";
import { MessageService } from 'primeng/api';

@Injectable({
    providedIn: 'root'
})
export class WhatsappService {

    baseUrl: string = environment.endpointApi;
    private wsEndpoint: string = environment.wsEndpointPrint;


    constructor(private http: HttpClient,
        private messageService: MessageService) {
    }

    sendMessage(model: WhatsappMessageRequest): Observable<Serie> {
        const  options = {
            headers: {hideLoader: 'S'}
        };
        return this.http.post<Serie>(`${this.baseUrl}/whatsapp/messages`, model, options);
    }
    

    sendDocument(model: WhatsappDocumentRequest): Observable<Serie> {
        const  options = {
            headers: {hideLoader: 'S'}
        };
        return this.http.post<Serie>(`${this.baseUrl}/whatsapp/documents`, model, options);
    }

    sendWhatsappMessage(numberData: string, message, filename, document): Observable<void> {
        return new Observable<any>(observer => {
            const numberW = formatNumberWhatsapp(numberData);
            if (numberW) {
                forkJoin(
                    [this.sendMessage({
                        number: numberW,
                        message
                    }), this.sendDocument({
                        number: numberW,
                        filename: `${filename}.pdf`,
                        document: `${document}`
                    })]
                ).subscribe(() => observer.next(), error => observer.error(error));
            } else {
                observer.next();
            }
        });
    }

    //Uso de socket
    closeSessionWhatsapp(data):Observable<any> {
        const options = {
            headers: {hideLoader: 'S', isPrinting: 'S'}
        };
        return this.http.post<any>(`${this.wsEndpoint}/whatsapp-session-logout`, data, options);
    }

    sendMessageWhatsappSocket(data):Observable<any> {
        const options = {
            headers: {hideLoader: 'S', isPrinting: 'S'}
        };
        return this.http.post<any>(`${this.wsEndpoint}/send-message-whatsapp-socket`, data,options)
    }
    //Uso de evolution
    getInstanceWhatsapp():Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/whatsapp/evolution/get-instance`);
    }

    getStatusInstanceWhatsapp():Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/whatsapp/evolution/get-status-instance`);
    }

    connectInstance():Observable<any> {
        const options = {
            headers: {hideLoader: 'S'}
        };
        return this.http.get<any>(`${this.baseUrl}/whatsapp/evolution/connet-instance`,options);
    }

    disconnectInstance():Observable<any> {
        const options = {
            headers: {hideLoader: 'S'}
        };
        return this.http.delete<any>(`${this.baseUrl}/whatsapp/evolution/disconnet-instance`,options);
    }

    createInstanceWhatsapp(body:string):Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/whatsapp/evolution/create-instance`,body);
    }

    deleteInstance(){
        return this.http.delete<any>(`${this.baseUrl}/whatsapp/evolution/delete-instance`);
    }

    evolutionSendMessage(body:any){
        const options = {
            headers: {hideLoader: 'S'}
        };
        return this.http.post<any>(`${this.baseUrl}/whatsapp/evolution/send-message`, body, options);
    }

    evolutionSendMedia(body:any){
        const options = {
            headers: {hideLoader: 'S'}
        };
        return this.http.post<any>(`${this.baseUrl}/whatsapp/evolution/send-media`, body, options);
    }

}
