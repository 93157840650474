import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '@env/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Page} from '@app/domain/page';
import {AccountsReceivable} from '@app/domain/accounts-receivable';
import {AccountsReceivableDetail} from '@app/domain/accounts-receivable-detail';
import {TotalCuentas} from '@app/domain/total-cuentas';
import {promiseBlob2Data} from '@app/utils/util-functions';
import {DomSanitizer} from '@angular/platform-browser';


@Injectable({
    providedIn: 'root'
})
export class AccountsReceivableService {

    private baseUrl: string = environment.endpointApi;

    constructor(private http: HttpClient,
                private sanitizer: DomSanitizer) {
    }

    findById(id): Observable<AccountsReceivable> {
        return this.http.get<AccountsReceivable>(`${this.baseUrl}/accounts-receivable/${id}`);
    }

    totales(params): Observable<TotalCuentas> {
        return this.http.get<TotalCuentas>(`${this.baseUrl}/accounts-receivable/operations/totales?${params}`);
    }

    findDetailById(id): Observable<AccountsReceivableDetail> {
        return this.http.get<AccountsReceivableDetail>(`${this.baseUrl}/accounts-receivable-detail/${id}`);
    }

    findAllPage(params: string): Observable<Page<AccountsReceivable>> {
        return this.http.get<any>(`${this.baseUrl}/accounts-receivable?${params}`);
    }

    findAllDetailByAccountReceivableId(id): Observable<AccountsReceivableDetail[]> {
        return this.http.get<any>(`${this.baseUrl}/accounts-receivable-detail?id=${id}`);
    }

    saveDetail(model: AccountsReceivableDetail, params): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/accounts-receivable-detail?${params}`, model);
    }

    updateDetail(model: AccountsReceivableDetail, params): Observable<any> {
        return this.http.put<any>(`${this.baseUrl}/accounts-receivable-detail?${params}`, model);
    }

    extend(id, dias): Observable<void> {
        return this.http.put<any>(`${this.baseUrl}/accounts-receivable/operations/extend/${id}?dias=${dias}`, null);
    }

    cancel(id): Observable<void> {
        return this.http.put<any>(`${this.baseUrl}/accounts-receivable/operations/cancel/${id}`, null);
    }

    refinance(id): Observable<void> {
        return this.http.put<any>(`${this.baseUrl}/accounts-receivable/operations/refinance/${id}`, null);
    }

    deleteDetail(id): Observable<void> {
        return this.http.delete<any>(`${this.baseUrl}/accounts-receivable-detail/${id}`);
    }

    openPdfDeudores(uuid: string, idCliente): Observable<any> {
        const url = `${environment.endpointApi}/public/pdf/cuenta-por-cobrar/${uuid}/${idCliente}`;
        return new Observable<any>(observer => {
            this.http.get(url, { responseType: 'blob' }).subscribe(blob => {
                promiseBlob2Data(blob).subscribe(data => {
                    const response: any = {
                        urlViewer: this.sanitizer.bypassSecurityTrustResourceUrl('/assets/pdfjs/web/viewer.html?file=' + data.objectUrl),
                        url: data.objectUrl,
                        originalUrl: url,
                        urlSafe: this.sanitizer.bypassSecurityTrustResourceUrl(url),
                        base64: data.base64
                    };
                    observer.next(response);
                    observer.complete();
                });
            });
        });
    }

    openPaymentPdf(idPago: number, options: any): Observable<any> {
        const url = this.getUrlPaymentPdf(idPago);
        return new Observable<any>(observer => {
            this.http.get(url, {responseType: 'blob'}).subscribe(blob => {
                promiseBlob2Data(blob).subscribe(data => {
                    const response: any = {
                        urlViewer: this.sanitizer.bypassSecurityTrustResourceUrl('/assets/pdfjs/web/viewer.html?file=' + data.objectUrl),
                        url: data.objectUrl,
                        originalUrl: url,
                        urlSafe: this.sanitizer.bypassSecurityTrustResourceUrl(url),
                        base64: data.base64
                    };
                    observer.next(response);
                    observer.complete();
                });
            });
        });
    }


    getUrlPaymentPdf(id: number) {
        return `${environment.endpointApi}/public/pdf/ticket/payment/${id}`;
    }
}
