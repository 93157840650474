import {NgModule, isDevMode, ErrorHandler, APP_INITIALIZER} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { DatePipe, HashLocationStrategy, LocationStrategy} from '@angular/common';

// PrimeNG Components for demos
import {FullCalendarModule} from '@fullcalendar/angular';

// Application Components
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {AppMainComponent} from './app.main.component';
import {AppMenuComponent} from './app.menu.component';
import {AppMenuitemComponent} from './app.menuitem.component';
import {AppBreadcrumbComponent} from './app.breadcrumb.component';
import {AppConfigComponent} from './app.config.component';
import {AppRightPanelComponent} from './app.rightpanel.component';
import {AppTopBarComponent} from './app.topbar.component';
import {AppFooterComponent} from './app.footer.component';

// Application services
import {MenuService} from './app.menu.service';

import {NgxsModule} from '@ngxs/store';
import {BreadcrumbState} from '@app/state/breadcrumb/breadcrumb.state';
import {SesionState} from '@app/state/sesion/sesion.state';
import {PedidosRestauranteState} from '@app/state/restaurant/pedidos/pedidos-restaurante.state';
import {CobradorRestauranteState} from '@app/state/restaurant/cobrador/cobrador-restaurante.state';
import {ComanderoRestauranteState} from '@app/state/restaurant/comandero/comandero-restaurante.state';
import {CocinaRestauranteState} from '@app/state/restaurant/cocina/cocina-restaurante.state';
import {environment} from '@env/environment';
import {NgxsStoragePluginModule} from '@ngxs/storage-plugin';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {NgxsLoggerPluginModule} from '@ngxs/logger-plugin';
import {SharedModule} from '@app/shared/shared.module';
import {NgxUiLoaderModule} from 'ngx-ui-loader';
import {LoaderComponent} from '@app/components/shared/loader/loader.component';
import {TokenInterceptor} from '@app/config/tokenInterceptor.service';
import {AuthGuardService} from '@app/config/auth-guard.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {CompanyService} from '@app/service/company.service';
import {OfficeService} from '@app/service/office.service';
import {UnitCodeService} from '@app/service/unit-code.service';
import {BankService} from '@app/service/bank.service';
import {BankAccountService} from '@app/service/bank-account.service';
import {BrandService} from '@app/service/brand.service';
import {CategoryService} from '@app/service/category.service';
import {SerieService} from '@app/service/serie.service';
import {CertificateService} from '@app/service/certificate.service';
import {SupplierService} from '@app/service/supplier.service';
import {InventoryService} from '@app/service/inventory.service';
import {TicketDesignService} from '@app/service/ticket-design.service';
import {StorageService} from '@app/service/storage.service';
import {TicketService} from '@app/service/ticket.service';
import {QzEscPosService} from '@app/service/qz-esc-pos.service';
import {DespatchAdviceService} from '@app/service/despatch-advice.service';
import {ListasService} from '@app/service/listas.service';
import {ConsultaDocumentosService} from '@app/service/consulta-documentos.service';
import {LoaderRequestService} from '@app/service/loader-request.service';
import {CashRegisterService} from '@app/service/cash-register.service';
import {CurrencyService} from '@app/service/currency.service';
import {QuotationService} from '@app/service/quotation.service';
import {ShortcutService} from '@app/service/shortcut.service';
import {SaleStationService} from '@app/service/sale-station.service';
import {AccountsReceivableService} from '@app/service/accounts-receivable.service';
import {InventoryTransferService} from '@app/service/inventory-transfer.service';
import {PreparationService} from '@app/service/preparation.service';
import {GroupService} from '@app/service/group.service';
import {CityService} from '@app/service/city.service';
import {MonedaPipe} from '@app/pipe/moneda.pipe';
import {TipoDocumentoPipe} from '@app/pipe/tipo-documento.pipe';
import {OpeningHourService} from '@app/service/opening-hour.service';
import {PaymentMethodService} from '@app/service/payment-method.service';
import {DeliveryService} from '@app/service/delivery.service';
import {CheckService} from '@app/service/check.service';
import {EventBusService} from '@app/service/event-bus.service';
import {DashboardService} from '@app/service/dashboard.service';
import {SocketIoService} from '@app/service/socket-io.service';
import {QzTrayService} from '@app/service/qz-tray.service';
import {ContractPlanService} from '@app/service/contract-plan.service';
import {ProductPlanFieldService} from '@app/service/product-plan-field.service';
import {MembershipService} from '@app/service/membership.service';
import {BatchProductService} from '@app/service/batch-product.service';
import {ProductService} from '@app/service/product.service';
import {CustomerService} from '@app/service/customer.service';
import {ConfigService} from './demo/service/app.config.service';
import {AppLogoutComponent} from './app.logout.component';
import {BreadcrumbService} from '@app/service/breadcrumb.service';
import {CompanySelectorComponent} from '@app/components/view/company-selector/company-selector.component';
import {WhatsappService} from '@app/service/whatsapp.service';
import {InstallmentService} from '@app/service/installment.service';
import {FiltersProductState} from '@app/state/forms/filters-product.state';
import {NgxsFormPluginModule} from '@ngxs/form-plugin';
import {ImportFileService} from '@app/service/import-file.service';
import {EmployeeService} from '@app/service/employee.service';
import {AttendanceRecordService} from '@app/service/attendance-record.service';
import {PersonService} from '@app/service/person.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import {CoffeEscPosService} from '@app/service/coffe-esc-pos.service';
import {PrintService} from '@app/service/print.service';
import {TicketFeeService} from '@app/service/ticket-fee.service';
import {ContingencyService} from '@app/service/contingency.service';
import { OrderByDatePipe } from '@app/pipe/order-by-date.pipe';
import { MultiAccountsInterceptor } from '@app/config/multi-accounts.interceptor';

@NgModule({
    imports: [
        BrowserModule,
        NgxsModule.forRoot([
            BreadcrumbState,
            SesionState,
            PedidosRestauranteState,
            CobradorRestauranteState,
            ComanderoRestauranteState,
            CocinaRestauranteState,
            FiltersProductState
        ], { developmentMode: !environment.production }),
        NgxsStoragePluginModule.forRoot(),
        NgxsReduxDevtoolsPluginModule.forRoot(),
        NgxsLoggerPluginModule.forRoot(),
        NgxsFormPluginModule.forRoot(),
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        SharedModule,
        NgxUiLoaderModule,
        FullCalendarModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: !isDevMode(),
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        })
    ],
    declarations: [
        AppLogoutComponent,
        AppComponent,
        AppMainComponent,
        AppMenuComponent,
        AppTopBarComponent,
        AppFooterComponent,
        AppRightPanelComponent,
        AppConfigComponent,
        AppBreadcrumbComponent,
        AppMenuitemComponent,
        LoaderComponent,
        CompanySelectorComponent
    ],
    providers: [
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MultiAccountsInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        AuthGuardService,
        ConfirmationService,
        MessageService,
        CompanyService,
        OfficeService,
        UnitCodeService,
        BankService,
        BankAccountService,
        BrandService,
        CategoryService,
        SerieService,
        CertificateService,
        CustomerService,
        SupplierService,
        ProductService,
        InventoryService,
        TicketDesignService,
        StorageService,
        TicketService,
        QzEscPosService,
        CoffeEscPosService,
        PrintService,
        DespatchAdviceService,
        ListasService,
        ConsultaDocumentosService,
        LoaderRequestService,
        CashRegisterService,
        CurrencyService,
        QuotationService,
        ShortcutService,
        SaleStationService,
        AccountsReceivableService,
        BreadcrumbService,
        InventoryTransferService,
        PreparationService,
        GroupService,
        CityService,
        DatePipe,
        MonedaPipe,
        OrderByDatePipe,
        TipoDocumentoPipe,
        OpeningHourService,
        PaymentMethodService,
        DeliveryService,
        CheckService,
        EventBusService,
        DashboardService,
        SocketIoService,
        QzTrayService,
        MenuService,
        ContractPlanService,
        ProductPlanFieldService,
        MembershipService,
        BatchProductService,
        ConfigService,
        WhatsappService,
        InstallmentService,
        ImportFileService,
        EmployeeService,
        AttendanceRecordService,
        PersonService,
        TicketFeeService,
        ContingencyService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
