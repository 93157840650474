import {AfterViewInit, ChangeDetectorRef, Component, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {AppMainComponent} from './app.main.component';
import {ShortcutMenu} from '@app/domain/shortcut-menu';
import {exhaustMap, filter, interval, mergeMap, Observable, Subject, takeUntil} from 'rxjs';
import {Office} from '@app/domain/office';
import {SaleStation} from '@app/domain/sale-station';
import {Select, Store} from '@ngxs/store';
import {SesionState} from '@app/state/sesion/sesion.state';
import {LoginService} from '@app/service/login.service';
import {UsuarioService} from '@app/service/usuario.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {ListasService} from '@app/service/listas.service';
import {ShortcutService} from '@app/service/shortcut.service';
import {SaleStationService} from '@app/service/sale-station.service';
import {QzTrayService} from '@app/service/qz-tray.service';
import {MessageService} from 'primeng/api';
import {environment} from '@env/environment';
import {SetCompanySelected, SetOffice, SetSaleStation, SetStatusClientWhatsappSession, SetStatusClientWhatsappSocket, SetStatusClientWhatsappEvolution} from '@app/state/sesion/sesion.actions';
import {isMobileDevice} from '@app/utils/util-functions';
import {Company} from '@app/domain/company';
import {ConfigCompany} from '@app/domain/config-company';
import {SocketIoService} from '@app/service/socket-io.service';
import {EventEnum} from '@app/enums/event.enum';
import { WhatsappService } from '@app/service/whatsapp.service';
import { error } from 'console';

@Component({
    selector: 'app-topbar',
    template: `
        <div class="layout-topbar">
			<div class="layout-topbar-wrapper">
                <div class="layout-topbar-left">
					<div class="layout-topbar-logo-wrapper">
						<a href="#" class="layout-topbar-logo">
							<img [src]="urlImageLogo" alt="mirage-layout" />
							<span class="app-name">Teki</span>
						</a>
					</div>

					<a href="#" class="sidebar-menu-button" (click)="appMain.onMenuButtonClick($event)">
						<i class="pi pi-bars"></i>
					</a>

					<!--<a href="#" class="megamenu-mobile-button" (click)="appMain.onMegaMenuMobileButtonClick($event)">
						<i class="pi pi-align-right megamenu-icon"></i>
					</a>-->

					<a href="#" class="topbar-menu-mobile-button" (click)="appMain.onTopbarMobileMenuButtonClick($event)">
						<i class="pi pi-ellipsis-v"></i>
					</a>

                    <app-company-selector>
                    </app-company-selector>

					<!--<div class="layout-megamenu-wrapper">
						<a class="layout-megamenu-button" href="#" (click)="appMain.onMegaMenuButtonClick($event)">
							<i class="pi pi-comment"></i>
							Mega Menu
						</a>
						<ul class="layout-megamenu" [ngClass]="{'layout-megamenu-active fadeInDown': appMain.megaMenuActive}"
                            (click)="appMain.onMegaMenuClick($event)">
							<li [ngClass]="{'active-topmenuitem': activeItem === 1}" (click)="mobileMegaMenuItemClick(1)">
								<a href="#">JavaServer Faces <i class="pi pi-angle-down"></i></a>
								<ul>
									<li class="active-row ">
										<i class="pi pi-circle-on"></i>
										<span>
                                        <h5>PrimeFaces</h5>
                                        <span>UI Components for JSF</span>
                                    </span>
									</li>
									<li>
										<i class="pi pi-circle-on"></i>
										<span>
                                        <h5>Premium Templates</h5>
                                        <span>UI Components for JSF</span>
                                    </span>
									</li>
									<li>
										<i class="pi pi-circle-on"></i>
										<span>
                                        <h5>Extensions</h5>
                                        <span>UI Components for JSF</span>
                                    </span>
									</li>
								</ul>
							</li>
							<li [ngClass]="{'active-topmenuitem': activeItem === 2}" (click)="mobileMegaMenuItemClick(2)">
								<a href="#">Angular <i class="pi pi-angle-down"></i></a>
								<ul>
									<li>
										<i class="pi pi-circle-on"></i>
										<span>
                                        <h5>PrimeNG</h5>
                                        <span>UI Components for Angular</span>
                                    </span>

									</li>
									<li>
										<i class="pi pi-circle-on"></i>
										<span>
                                        <h5>Premium Templates</h5>
                                        <span>UI Components for Angular</span>
                                    </span>
									</li>
								</ul>
							</li>
							<li [ngClass]="{'active-topmenuitem': activeItem === 3}" (click)="mobileMegaMenuItemClick(3)">
								<a href="#">React <i class="pi pi-angle-down"></i></a>
								<ul>
									<li>
										<i class="pi pi-circle-on"></i>
										<span>
                                        <h5>PrimeReact</h5>
                                        <span>UI Components for React</span>
                                    </span>
									</li>
									<li class="active-row">
										<i class="pi pi-circle-on"></i>
										<span>
                                        <h5>Premium Templates</h5>
                                        <span>UI Components for React</span>
                                    </span>
									</li>
								</ul>
							</li>
						</ul>
					</div>-->
                </div>
                <div class="layout-topbar-right fadeInDown">
					<ul class="layout-topbar-actions">
						<!--<li #search class="search-item topbar-item" [ngClass]="{'active-topmenuitem': appMain.activeTopbarItem === search}">
							<a href="#" class="topbar-search-mobile-button" (click)="appMain.onTopbarItemClick($event,search)">
								<i class="topbar-icon pi pi-search"></i>
							</a>
							<ul class="search-item-submenu fadeInDown" (click)="appMain.topbarItemClick = true">
								<li>
                                    <span class="md-inputfield search-input-wrapper">
                                        <input pInputText placeholder="Search..."/>
                                        <i class="pi pi-search"></i>
                                    </span>
                                </li>
                            </ul>
                        </li>-->
						<!--<li #calendar class="topbar-item" [ngClass]="{'active-topmenuitem': appMain.activeTopbarItem === calendar}">
							<a href="#" (click)="appMain.onTopbarItemClick($event,calendar)">
								<i class="topbar-icon pi pi-calendar"></i>
							</a>
							<ul class="fadeInDown" (click)="appMain.topbarItemClick = true">
								<li class="layout-submenu-header">
									<h1>Calendar</h1>
								</li>
								<li class="calendar">
                                    <p-calendar [inline]="true"></p-calendar>
								</li>
							</ul>
						</li>-->

						<!--<li #message class="topbar-item" [ngClass]="{'active-topmenuitem': appMain.activeTopbarItem === message}">
							<a href="#" (click)="appMain.onTopbarItemClick($event,message)">
								<i class="topbar-icon pi pi-inbox"></i>
							</a>
							<ul class="fadeInDown">
								<li class="layout-submenu-header">
									<h1>Messages</h1>
									<span>Today, you have new 4 messages</span>
								</li>
								<li class="layout-submenu-item">
									<img src="assets/layout/images/topbar/avatar-cayla.png" alt="mirage-layout" width="35" />
									<div class="menu-text">
										<p>Override the digital divide</p>
										<span>Cayla Brister</span>
									</div>
									<i class="pi pi-angle-right"></i>
								</li>
								<li class="layout-submenu-item">
									<img src="assets/layout/images/topbar/avatar-gabie.png" alt="mirage-layout" width="35" />
									<div class="menu-text">
										<p>Nanotechnology immersion</p>
										<span>Gabie Sheber</span>
									</div>
									<i class="pi pi-angle-right"></i>
								</li>
								<li class="layout-submenu-item">
									<img src="assets/layout/images/topbar/avatar-gaspar.png" alt="mirage-layout" width="35" />
									<div class="menu-text">
										<p>User generated content</p>
										<span>Gaspar Antunes</span>
									</div>
									<i class="pi pi-angle-right"></i>
								</li>
								<li class="layout-submenu-item">
									<img src="assets/layout/images/topbar/avatar-tatiana.png" alt="mirage-layout" width="35" />
									<div class="menu-text">
										<p>The holistic world view</p>
										<span>Tatiana Gagelman</span>
									</div>
									<i class="pi pi-angle-right"></i>
								</li>
							</ul>
						</li>-->

                        <li class="search-item topbar-item">
                            <div style="position: relative; top: -2px; margin-left: 0.5em;">
                                <p-dropdown dataKey="id" [(ngModel)]="puntoVenta" (onChange)="changePuntoVenta()"
                                            [style]="{'width':'100%'}" [options]="puntosVenta"
                                            optionLabel="nombre" name="puntoVenta">
                                    <ng-template let-item pTemplate="selectedItem">
                                        <span style="vertical-align:middle;"><span class="fa fa-building" aria-hidden="true"></span></span>
                                        <span style="vertical-align:middle; margin-left: .5em">{{item.nombre}}</span>
                                    </ng-template>
                                </p-dropdown>
                            </div>
                        </li>

                        <li class="search-item topbar-item">
                            <div style="position: relative; top: -2px; margin-left: 0.5em;">
                                <p-dropdown dataKey="id" [(ngModel)]="estacionVenta" (onChange)="changeSaleStation()"
                                            [style]="{'width':'100%'}" [options]="estacionesVenta"
                                            optionLabel="nombre">
                                    <ng-template let-item pTemplate="selectedItem">
                                        <span style="vertical-align:middle;"><span class="fa fa-television" aria-hidden="true"></span></span>
                                        <span style="vertical-align:middle; margin-left: .5em">{{item.nombre}}</span>
                                    </ng-template>
                                </p-dropdown>
                            </div>
                        </li>
                        <li class="topbar-item" *ngFor="let item of shortcuts">
                            <a [routerLink]="item.ruta" [pTooltip]="item.nombre" tooltipPosition="bottom">
                                <span *ngIf="item.icono.startsWith('fa')" [class]="'topbar-icon '+ item.icono"></span>
                                <i *ngIf="item.icono.startsWith('pi')" [class]="'topbar-icon '+ item.icono"></i>
                            </a>
                        </li>

                        <li *ngIf="isTypeClientPrintQz" class="topbar-item">
                            <a href="#" (click)="connectQzServer($event)">
                                <img *ngIf="isActiveQz" pTooltip="Conectado" class="cursor-pointer" style="width: 25px" src="assets/resources/images/print-connected.svg" alt="">
                                <img  *ngIf="!isActiveQz" pTooltip="Desconectado" class="cursor-pointer" style="width: 25px" src="assets/resources/images/print-disconnected.svg" alt="">
                            </a>
                        </li>

                        <li *ngIf="isTypeClientPrintCoffe" class="topbar-item">
                            <img *ngIf="statusClientPrinterCoffe" pTooltip="Conectado a cliente de impresión" class="cursor-pointer" style="width: 25px" src="assets/resources/images/print-connected.svg" alt="">
                            <img  *ngIf="!statusClientPrinterCoffe" pTooltip="Desconectado" class="cursor-pointer" style="width: 25px" src="assets/resources/images/print-disconnected.svg" alt="">
                        </li>
                        <li *ngIf="this.configCompany?.tipoClienteWhatsapp === 'EVOLUTION'" class="ml-2">
                            <i pTooltip="Sesion iniciada" *ngIf="WhatsappEvolucionLogued" [routerLink]="['/whatsapp/whatsapp-login']" class="topbar-icon pi pi-whatsapp text-green-500 text-xl cursor-pointer"  ></i>
                            <i pTooltip="Sesion aun no iniciada"  *ngIf="!WhatsappEvolucionLogued" [routerLink]="['/whatsapp/whatsapp-login']" class="topbar-icon pi pi-whatsapp text-xl cursor-pointer"  ></i>
                        </li>                        
                        <li *ngIf="this.configCompany?.tipoClienteWhatsapp === 'SOCKET' && statusClientWhatsapp" >
                            <i pTooltip="Sesion iniciada" *ngIf="WhatsappClientLogued" [routerLink]="['/whatsapp/estado-session']" class="topbar-icon pi pi-whatsapp text-green-500 text-xl cursor-pointer"  ></i>
                            <i pTooltip="Sesion aun no iniciada"  *ngIf="!WhatsappClientLogued" [routerLink]="['/whatsapp/estado-session']" class="topbar-icon pi pi-whatsapp text-xl cursor-pointer"  ></i>
                        </li>

						<!--<li #gift class="topbar-item" [ngClass]="{'active-topmenuitem': appMain.activeTopbarItem === gift}">
							<a href="#" (click)="appMain.onTopbarItemClick($event,gift)">
								<i class="topbar-icon pi pi-envelope"></i>
							</a>
							<ul class="fadeInDown">
								<li class="layout-submenu-header">
									<h1>Deals</h1>
								</li>

								<li class="deals">
									<ul>
										<li>
											<img src="assets/layout/images/topbar/deal-icon-sapphire.png" alt="mirage-layout" width="35" />
											<div class="menu-text">
												<p>Sapphire</p>
												<span>Angular</span>
											</div>
											<i class="pi pi-angle-right"></i>
										</li>
										<li>
											<img src="assets/layout/images/topbar/deal-icon-roma.png" alt="mirage-layout" width="35" />
											<div class="menu-text">
												<p>Roma</p>
												<span>Minimalism</span>
											</div>
											<i class="pi pi-angle-right"></i>
										</li>
										<li>
											<img src="assets/layout/images/topbar/deal-icon-babylon.png" alt="mirage-layout" width="35" />
											<div class="menu-text">
												<p>Babylon</p>
												<span>Powerful</span>
											</div>
											<i class="pi pi-angle-right"></i>
										</li>
									</ul>
									<ul>
										<li>
											<img src="assets/layout/images/topbar/deal-icon-harmony.png" alt="mirage-layout" width="35" />
											<div class="menu-text">
												<p>Harmony</p>
												<span>USWDS</span>
											</div>
											<i class="pi pi-angle-right"></i>
										</li>
										<li>
											<img src="assets/layout/images/topbar/deal-icon-prestige.png" alt="mirage-layout" width="35" />
											<div class="menu-text">
												<p>Prestige</p>
												<span>Elegancy</span>
											</div>
											<i class="pi pi-angle-right"></i>
										</li>
										<li>
											<img src="assets/layout/images/topbar/deal-icon-ultima.png" alt="mirage-layout" width="35" />
											<div class="menu-text">
												<p>Ultima</p>
												<span>Material</span>
											</div>
											<i class="pi pi-angle-right"></i>
										</li>
									</ul>
								</li>
							</ul>
						</li>-->

						<li #profile class="topbar-item profile-item" [ngClass]="{'active-topmenuitem': appMain.activeTopbarItem === profile}">
							<a href="#" (click)="appMain.onTopbarItemClick($event,profile)">
                            <span class="profile-image-wrapper">
                               <img [src]="appMain.userLogin.avatarUrl" alt="mirage-layout"/>
                            </span>
								<span class="profile-info-wrapper">
                                 <h3>{{appMain.userLogin.name}}</h3>
                                <span>{{appMain.userLogin.cargo}}</span>
                            </span>
							</a>
							<ul class="profile-item-submenu fadeInDown">
								<li class="profile-submenu-header">
									<div class="performance">
                                        <span>{{appMain.razonSocial}}</span>
										<img src="assets/layout/images/topbar/asset-bars.svg" alt="mirage-layout" />
									</div>
                                    <div class="profile">
                                        <img [src]="appMain.userLogin.avatarUrl" alt="mirage-layout"
                                             width="40"/>
                                        <h1>{{appMain.userLogin.name}}</h1>
                                        <span>{{appMain.userLogin.cargo}}</span>
                                    </div>
								</li>
                                <li *appPermission="'CAMBIAR_CONFIGURACION'" routerLink="/mi-empresa" class="layout-submenu-item">
                                    <span class="fa fa-fw fa-cog icon icon-1"></span>
                                    <div class="menu-text">
                                        <p>Configuración</p>
                                    </div>
                                </li>
                                <li routerLink="/pagos" class="layout-submenu-item">
                                    <span class="fa fa-fw fa fa-money icon icon-1"></span>
                                    <div class="menu-text">
                                        <p>Mis pagos</p>
                                    </div>
                                </li>
                                <li routerLink="/mi-perfil" class="layout-submenu-item">
                                    <span class="fa fa-fw fa-user icon icon-1"></span>
                                    <div class="menu-text">
                                        <p>Mi perfil</p>
                                    </div>
                                </li>
                                <li routerLink="/cambiar-clave" class="layout-submenu-item">
                                    <span class="fa fa-fw fa-user-secret icon icon-1"></span>
                                    <div class="menu-text">
                                        <p>Cambiar contraseña</p>
                                    </div>
                                </li>
                                <li class="layout-submenu-footer">
                                    <button routerLink="/logout" class="signout-button">Salir</button>
                                    <!--<button class="buy-mirage-button">Buy Mirage</button>-->
                                </li>
							</ul>
						</li>
						<li>
							<a href="#" class="layout-rightpanel-button" (click)="appMain.onRightPanelButtonClick($event)">
								<i class="pi pi-arrow-left"></i>
							</a>
						</li>
                    </ul>

					<!--<ul class="profile-mobile-wrapper">
						<li #mobileProfile class="topbar-item profile-item" [ngClass]="{'active-topmenuitem': appMain.activeTopbarItem === mobileProfile}">
							<a href="#" (click)="appMain.onTopbarItemClick($event,mobileProfile)">
                            <span class="profile-image-wrapper">
                                <img src="assets/layout/images/topbar/avatar-eklund.png" alt="mirage-layout" />
                            </span>
								<span class="profile-info-wrapper">
                                <h3>Olivia Eklund</h3>
                                <span>Design</span>
                            </span>
							</a>
							<ul class="fadeInDown">
								<li class="profile-submenu-header">
									<div class="performance">
										<span>Weekly Performance</span>
										<img src="assets/layout/images/topbar/asset-bars.svg" alt="mirage-layout" />
									</div>
									<div class="profile">
										<img src="assets/layout/images/topbar/avatar-eklund.png" alt="mirage-layout" width="45" />
										<h1>Olivia Eklund</h1>
										<span>Design</span>
									</div>
								</li>
								<li>
									<i class="pi pi-list icon icon-1"></i>
									<div class="menu-text">
										<p>Tasks</p>
										<span>3 open issues</span>
									</div>
									<i class="pi pi-angle-right"></i>
								</li>
								<li>
									<i class="pi pi-shopping-cart icon icon-2"></i>
									<div class="menu-text">
										<p>Payments</p>
										<span>24 new</span>
									</div>
									<i class="pi pi-angle-right"></i>
								</li>
								<li>
									<i class="pi pi-users icon icon-3"></i>
									<div class="menu-text">
										<p>Clients</p>
										<span>+80%</span>
									</div>
									<i class="pi pi-angle-right"></i>
								</li>
								<li class="layout-submenu-footer">
									<button class="signout-button">Sign Out</button>
									<button class="buy-mirage-button">Buy Mirage</button>
								</li>
							</ul>
						</li>
					</ul>-->

                    <ul class="profile-mobile-wrapper">
                        <li #mobileProfile class="topbar-item profile-item"
                            [ngClass]="{'active-topmenuitem': appMain.activeTopbarItem === mobileProfile}">
                            <a href="#" (click)="appMain.onTopbarItemClick($event,mobileProfile)">
                            <span class="profile-image-wrapper">
                            <img [src]="appMain.userLogin.avatarUrl" alt="mirage-layout"/>
                            </span>
                                <span class="profile-info-wrapper">
                                <h3>{{appMain.userLogin.name}}</h3>
                                <span>{{appMain.userLogin.cargo}}</span>
                            </span>
                            </a>
                            <ul class="fadeInDown">
                                <li class="profile-submenu-header">
                                    <div class="performance">
                                        <span>{{appMain.razonSocial}}</span>
                                        <img src="assets/layout/images/topbar/asset-bars.svg" alt="mirage-layout"/>
                                    </div>
                                    <div class="profile">
                                        <img [src]="appMain.userLogin.avatarUrl"  alt="mirage-layout" width="45"/>
                                        <h1>{{appMain.userLogin.name}}</h1>
                                        <span>{{appMain.userLogin.cargo}}</span>
                                    </div>
                                </li>
                                <li>
                                    <i class="pi pi-list icon icon-1"></i>
                                    <div class="menu-text">
                                        <p><a routerLink="/mi-perfil">Mi perfil</a></p>
                                    </div>
                                    <i class="pi pi-angle-right"></i>
                                </li>
                                <li class="layout-submenu-footer">
                                    <button routerLink="/logout" class="signout-button">Salir</button>
                                    <!-- <button class="buy-mirage-button">Buy Mirage</button>-->
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    `
})
export class AppTopBarComponent implements  OnInit, OnDestroy, AfterViewInit {
    private unsubscribe$ = new Subject<void>();
    shortcuts: ShortcutMenu[] = [];

    // tslint:disable-next-line:variable-name
    _roles: any[];
    activeItem: number;
    puntoVenta: Office;
    estacionVenta: SaleStation;

    puntosVenta: Office[] = this.loginService.getPuntosVentaUsuario();
    estacionesVenta: SaleStation[] = [];
    urlImageLogo: string;
    connectQzHistory;

    @Select(SesionState.getCompany) company$: Observable<Company>;
    company: Company;

    @Select(SesionState.getConfig) configCompany$: Observable<ConfigCompany>;
    configCompany: ConfigCompany = {};

    @Select(SesionState.getStatusClientWhatsappEvolution) statusClientWhatsappstatusEvolution$: Observable<'open' | 'close' | 'connecting'>;


    statusClientPrinterCoffe: boolean;
    statusClientWhatsapp: boolean;
    WhatsappClientLogued = false;

    WhatsappEvolucionInstance = false;
    WhatsappEvolucionLogued = false;

    @Input()
    set roles(roles: any[]) {
        this._roles = roles;
        this.loadShortcuts();
    }

    get roles() {
        return this._roles;
    }

    @Select(SesionState.getOffice) puntoVenta$: Observable<Office>;
    @Select(SesionState.getSaleStation) estacionVenta$: Observable<SaleStation>;

    constructor(public appMain: AppMainComponent, private loginService: LoginService,
                private userService: UsuarioService,
                private router: Router,
                private store: Store,
                public ls: ListasService,
                private shortcutService: ShortcutService,
                private saleStationService: SaleStationService, public qzTrayService: QzTrayService,
                private cd: ChangeDetectorRef,
                private messageService: MessageService,
                private socketIoService: SocketIoService,
                private whatsappService: WhatsappService,
                private activeRoute: ActivatedRoute
            ) {}

    ngOnInit(): void {
        this.configCompany$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(c => {
                this.configCompany = c;
                if(this.configCompany.tipoClienteWhatsapp === 'EVOLUTION'){
                    this.whatsappService.getStatusInstanceWhatsapp().subscribe(data=>{
                        if (data.instance.state === 'connecting') {
                            this.messageService.add({severity: 'info', summary: 'Informacion', detail: 'No se puede conectar con el servidor de whatsapp, intentando conectar manualmente'});
                        }
                        if (data.instance.state === 'close') {
                            this.messageService.add({severity: 'warn', summary: 'Advertencia', detail: 'El servidor de whatsapp no se encuentra conectado, por favor conectese', life: 5000});
                        }
                        this.WhatsappEvolucionLogued = data.instance.state === 'open';
                        this.store.dispatch(new SetStatusClientWhatsappEvolution(data.instance.state));
                    },
                    error=>{
                        console.error(error);
                        if (error.status === 404) {
                            this.messageService.add({severity: 'info', summary: 'Info', detail: 'Registro de numero necesario, registrese para poder enviar mensajes'});
                            return
                        }
                        this.messageService.add({severity: 'error', summary: 'Error', detail: 'No se pudo establecer conexión con el servidor de whatsapp'});
                    })
                }
            });
        this.urlImageLogo = environment.urlLogo;
        this.company$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(ev => {
                    this.company = ev;
            });
        
        this.statusClientWhatsappstatusEvolution$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(data => {
            this.WhatsappEvolucionLogued = data ==='open';
        });

        this.statusClientWhatsappstatusEvolution$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(data => {
                this.WhatsappEvolucionLogued = data === 'open';
            });
        this.puntoVenta$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(pv => {
                this.puntoVenta = pv;
                this.loadSaleStations().then();
            });
        this.estacionVenta$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(ev => {
                this.estacionVenta = ev;
                this.connectQzServer();
            });
        this.processQueuePrinter();
        interval(1000)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(v => {
                if (!this.isActiveQz && this.connectQzHistory) {
                    this.connectQzServer();
                }
        });
        this.socketIoService.onEvent(EventEnum.STATUS_CLIENT_PRINTER)
            .pipe(
                takeUntil(this.unsubscribe$)
            ).subscribe(data => {
                console.log(data);
                this.statusClientPrinterCoffe = data;
        });

        this.socketIoService.onEvent(EventEnum.STATUS_CLIENT_WHTASAPP_SOCKET)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(data => {
            if (this.configCompany.tipoClienteWhatsapp === 'SOCKET') {
                this.store.dispatch(new SetStatusClientWhatsappSocket(data));
                console.log(data);
                this.statusClientWhatsapp = data;                
            }
        });

        this.socketIoService.onEvent(EventEnum.STATUS_CLIENT_WHTASAPP)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(data => {
            console.log(data);
            this.store.dispatch(new SetStatusClientWhatsappSession(data));
            this.WhatsappClientLogued = data.logued;
        });
    }

    ngAfterViewInit(): void {
        if (this.configCompany.clienteImpresion === 'QZ') {
            this.qzTrayService.changeStatusConnect
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(
                    (st) => {
                        console.log('Status llego', st);
                        this.cd.detectChanges();
                    }
                );
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        if (this.configCompany.clienteImpresion === 'QZ') {
            this.qzTrayService.disconnect().then();
        }
    }

    get isActiveQz() {
        return this.qzTrayService.isActiveQz();
    }

    get isTypeClientPrintQz() {
        return this.configCompany ? this.configCompany.clienteImpresion === 'QZ' : null;
    }

    get isTypeClientPrintCoffe() {
        return this.configCompany?.clienteImpresion === 'COFFE' || this.configCompany?.clienteImpresion === 'COFFE_LOCAL';
    }

    processQueuePrinter() {
        if (this.configCompany.clienteImpresion === 'QZ') {
            this.qzTrayService.queue
                .pipe(mergeMap((d: any) => this.qzTrayService.printQz(d.printer, d.data, d.options), 1))
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe();
        }
    }

    connectQzServer(e?: any) {
        if (this.configCompany.clienteImpresion === 'QZ') {
            if (e) {
                e.preventDefault();
            }
            if (this.estacionVenta && this.estacionVenta.impresoraComprobante && this.estacionVenta.impresoraComprobante.ip) {
                this.qzTrayService.connectQZ({
                    host: this.estacionVenta.impresoraComprobante.ip
                }).subscribe((c) => {
                    if (c) {
                        // tslint:disable-next-line:max-line-length
                        this.messageService.add({
                            severity: 'success',
                            summary: 'Servidor de impresoras',
                            detail: 'Se a conectado correctamente.'
                        });
                        this.connectQzHistory = true;
                    }
                });
            }
        }
    }

    @HostListener('window:focus', ['$event'])
    onFocused(event) {
        if (isMobileDevice()) {
            if (this.connectQzHistory && this.configCompany.clienteImpresion === 'QZ') {
                this.qzTrayService.disconnect().then(() => this.connectQzServer());
            }
        }
    }

    changePuntoVenta() {
        this.store.dispatch(new SetOffice({office: this.puntoVenta}));
        const empresas = [this.company, ...this.loginService.getUser().empresasAdjuntas];
        if (this.puntoVenta.rucAsignado && empresas.find(ea => ea.ruc === this.puntoVenta.rucAsignado)) {
            this.store.dispatch(new SetCompanySelected(empresas.find(ea => ea.ruc === this.puntoVenta.rucAsignado)));
        }
        this.loadSaleStations().then(() => {
            this.store.dispatch(new SetSaleStation({saleStation: this.estacionesVenta[0]}));
        });
    }

    changeSaleStation() {
        this.store.dispatch(new SetSaleStation({saleStation: this.estacionVenta}));
    }

    mobileMegaMenuItemClick(index) {
        this.appMain.megaMenuMobileClick = true;
        this.activeItem = this.activeItem === index ? null : index;
    }

    loadSaleStations() {
        return new Promise(resolve => {
            if (this.puntoVenta) {
                const params = [
                    `paginacion=${false}`,
                    `idPuntoVenta=${this.puntoVenta.id}`
                ];
                this.saleStationService.findAllList(params.join('&'))
                    .subscribe(data => {
                        if (this.loginService.getUser().estacionVenta) {
                            this.estacionesVenta = data.filter(ev => ev.id === this.loginService.getUser().estacionVenta.id);
                        } else {
                            this.estacionesVenta = data;
                        }
                        resolve(undefined);
                    });
            } else {
                this.estacionesVenta = [];
                resolve(undefined);
            }
        });
    }

    loadShortcuts() {
        if (this.roles) {
            this.shortcutService.findAll(this.loginService.getUser().empresa.id, false).subscribe(data => {
                this.shortcuts = data;
                this.shortcuts.forEach(s => {
                    const rolesRequired = s.roles.split(',');
                    let visible = false;
                    rolesRequired.forEach(rr => {
                        if (this.roles.find(r => r === rr)) {
                            visible = true;
                        }
                    });
                    s.visible = visible;
                });
                this.shortcuts = this.shortcuts.filter(s => s.visible);
            });
        }
    }

}
