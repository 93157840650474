import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginService } from '@app/service/login.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import * as jwt_decode from 'jwt-decode';

@Injectable()
export class MultiAccountsInterceptor implements HttpInterceptor {

  constructor(
    public auth: LoginService,
    private messageService: MessageService,
    private router: Router,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let token: any = {};
    if (!this.auth.getToken()) {
      return next.handle(request);
    }
    try {
      token = jwt_decode.jwtDecode(this.auth.getToken());
      const rucActual = JSON.parse(this.auth.getCompanyInfo())?.ruc;
      if (token?.company) {
        const isTokenCompany = token?.company?.includes(rucActual) || false;
        console.log('Token pertenece a la empresa seleccionada', rucActual);
        console.log('Compañias relacionadas', token.company);
        if (!isTokenCompany) {
          console.log('Token no pertenece a la empresa seleccionada');
          this.auth.logOut();
          this.router.navigate(['/login']);
          this.messageService.add({severity: 'error', summary: 'Error', detail: 'Error de autenticación.'});
        }
      }
    } catch (Error) {
      console.log('Error al decodificar el token', Error);
    }
    return next.handle(request);
  }
}
